import { useNuxtApp } from 'nuxt/app';

import { showAlert } from '@/composables/useDialog';

export const throwContentError = async (content: string) => {
  const nuxtApp = useNuxtApp();
  const t = (nuxtApp.$i18n as any)?.t;

  await showAlert({
    content: t(content),
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    confirmClasses: '!w-full !max-w-full'
  });
};
